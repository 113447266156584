import styled from '@emotion/styled';
import React, {useCallback, useState} from 'react';

import useDict, {useCommonDict} from '@/common/hooks/useDict';
import {shadowColor} from '@/common/styles/whColor';
import {WHFont} from '@/common/styles/whFont';
import {TextButton} from '@/components/button';
import WFormDialogTextField from '@/components/figma/others/dialog/form/WFormDialogTextField';
import WLabelContent from '@/components/figma/others/label/WLabelContent';

import {Locale} from '../../common/redux/state-types/localeStateType';
import WLoadingComponent from '../../components/figma/others/stepper/WLoadingComponent';
import WorkhubMinLogoIcon from '../../components/icon/WorkhubMinLogoIcon';
import SimpleSnackbar, {SimpleSnackbarState, SimpleSnackbarType} from '../../components/snackbar/SimpleSnackbar';
import {AuthService} from '../../services/auth/authService';

interface P {
  transitionLoginScreen: () => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const LoginFormArea = styled.div`
  width: 385px;
  height: 680px;
  padding: var(--spacing-16);
  box-sizing: border-box;
  box-shadow: 0 4px 10px ${shadowColor.shadowAlpha020};
  border-radius: var(--radius-l);
  background-color: var(--surface-neutral-low);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// デザインどうなるかわからんからとりあえずworkhubってかいとく
const TitleArea = styled.div`
  height: 50px;
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DescriptionArea = styled.div`
  margin-top: 50px;
  height: 94px;
  width: 227px;
  text-align: center;
`;

const InputArea = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;

const ErrorMessageArea = styled.div`
  color: var(--text-semantic-error);
  margin-top: var(--spacing-16);
`;

const ButtonArea = styled.div`
  display: flex;
  gap: var(--spacing-8);
  margin-top: 86px;
  width: 80%;
`;

const Button = styled(TextButton)`
  width: 50%;
  height: 36px;
`;

const DescriptionTitle = styled.span`
  ${WHFont.headlineSmall}
`;

const DescriptionText = styled.span`
  margin-top: var(--spacing-16);
  ${WHFont.overline}
`;

const dictDef = {
  emailAddress: {
    default: {
      default: 'メールアドレス',
      [Locale.en_US]: 'Email address',
    },
  },
  failedToSendPasswordResetEmail: {
    default: {
      default: 'メール送信に失敗しました',
      [Locale.en_US]: 'Failed to send Email',
    },
  },
  successToSendPasswordResetEmail: {
    default: {
      default: 'パスワードリセットメールを送信しました',
      [Locale.en_US]: 'Password reset Email is sent',
    },
  },
  descriptionTitle: {
    default: {
      default: 'パスワードリセット',
      [Locale.en_US]: 'Password Reset',
    },
  },
  descriptionText: {
    default: {
      default: 'パスワードをリセットするためのURLを発行します。送信先のメールアドレスを入力してください。',
      [Locale.en_US]: 'Please input your email below. A link to reset your password will be sent.',
    },
  },
};

const ResetPasswordScreen = React.memo(function ResetPasswordScreen(props: P) {
  const dict = useDict(dictDef);
  const commonDict = useCommonDict();
  const {transitionLoginScreen, email, setEmail} = props;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [running, setRunning] = useState<boolean>(false);
  const [snackbarState, setSnackbarState] = useState<SimpleSnackbarState>({
    open: false,
    message: '',
    type: SimpleSnackbarType.Success,
  });
  const onChangeEmail = useCallback(
    event => {
      setEmail(event.target.value);
    },
    [setEmail]
  );
  const sendEmail = useCallback(async () => {
    setRunning(true);
    try {
      await AuthService.issueBulkPasswordResetToken(email, 'workhub');
      setSnackbarState({
        open: true,
        message: dict.successToSendPasswordResetEmail,
        type: SimpleSnackbarType.Success,
      });
    } catch {
      setErrorMessage(dict.failedToSendPasswordResetEmail);
    } finally {
      setRunning(false);
    }
  }, [dict.failedToSendPasswordResetEmail, dict.successToSendPasswordResetEmail, email]);

  return (
    <LoginFormArea>
      {running && <WLoadingComponent message={''} />}
      <TitleArea>
        <WorkhubMinLogoIcon size={51} />
      </TitleArea>
      <DescriptionArea>
        <DescriptionTitle>{dict.descriptionTitle}</DescriptionTitle>
        <DescriptionText>{dict.descriptionText}</DescriptionText>
      </DescriptionArea>
      <InputArea>
        <WLabelContent
          label={dict.emailAddress}
          content={
            <WFormDialogTextField
              id={'email'}
              name={commonDict.emailAddress}
              type={'email'}
              value={email}
              onChange={onChangeEmail}
              role={'textbox'}
            />
          }
          width={'80%'}
        />
        <ButtonArea>
          <Button type={'secondary'} onClick={transitionLoginScreen} disabled={running}>
            {commonDict.cancel}
          </Button>
          <Button type={'primary'} onClick={sendEmail} disabled={!email || running}>
            {commonDict.send}
          </Button>
        </ButtonArea>
        {errorMessage && <ErrorMessageArea>{errorMessage}</ErrorMessageArea>}
        <SimpleSnackbar snackbarState={snackbarState} setSnackbarState={setSnackbarState} />
      </InputArea>
    </LoginFormArea>
  );
});

export default ResetPasswordScreen;
