import {css} from '@emotion/react';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import {forwardRef, type ForwardRefRenderFunction, type PropsWithChildren} from 'react';

import {WHColor} from '@/common/styles/whColor';
import {WHFontCss} from '@/common/styles/whFont';
import {WHRadius} from '@/common/styles/whRadius';
import {WHSpacing} from '@/common/styles/whSpacing';
import * as Icon from '@/components/icon';

const overlay = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const styles = {
  root: css`
    ${WHFontCss.bodyMediumStrong};

    height: 32px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 0 ${WHSpacing.spacing16};
    border-radius: ${WHRadius.radiusM};
    color: ${WHColor.text.neutralPrimary};

    &:focus {
      box-shadow: inset 0 0 0 2px ${WHColor.border.brandAccent};
    }

    &:hover::after {
      ${overlay};

      content: '';
      background-color: ${WHColor.state.hoverDefault};
    }

    &[data-type='primary'] {
      background-color: ${WHColor.surface.brandPrimary};
      color: ${WHColor.text.neutralWhitePrimary};

      &:hover::after {
        background-color: ${WHColor.state.hoverOnDeep};
      }
    }

    &[data-type='secondary'] {
      background-color: ${WHColor.surface.neutralHigh};
    }

    &[data-type='disruptive'] {
      background-color: ${WHColor.surface.semanticError};
      color: ${WHColor.text.neutralWhitePrimary};
    }

    &[data-processing='true'],
    &[disabled] {
      color: ${WHColor.text.neutralDisabled};

      &:not([data-type='ghost']) {
        background-color: ${WHColor.object.neutralDisabled};
      }
    }
  `,
  label: css`
    display: flex;
    align-items: center;
    column-gap: ${WHSpacing.spacing8};

    > svg {
      width: 16px;
      height: 16px;

      &:first-of-type {
        margin-left: -${WHSpacing.spacing4};
      }

      &:last-of-type {
        margin-right: -${WHSpacing.spacing4};
      }
    }
  `,
  processing: css`
    ${overlay};

    display: flex;
    align-items: center;
    justify-content: center;
  `,
  done: css`
    ${overlay};

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${WHColor.surface.semanticSuccess};
    color: ${WHColor.text.neutralWhitePrimary};
  `,
} as const;

type Props = PropsWithChildren<{
  size?: 'small' | 'medium';
  type?: 'primary' | 'secondary' | 'disruptive' | 'ghost';
  processing?: boolean;
  done?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}>;

const _TextButton: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  {size = 'medium', type = 'primary', processing, done, disabled, className, onClick, children},
  ref
) => {
  return (
    <ButtonBase
      ref={ref}
      css={styles.root}
      className={className}
      disabled={disabled}
      data-size={size}
      data-type={type}
      data-processing={processing}
      onClick={onClick}
    >
      <span css={styles.label}>{children}</span>
      {processing && (
        <span css={styles.processing}>
          <CircularProgress size={24} />
        </span>
      )}
      {done && (
        <span css={styles.done}>
          <Icon.done />
        </span>
      )}
    </ButtonBase>
  );
};

export const TextButton = forwardRef(_TextButton);
