// generated by gen/color
/** @deprecated この定義は使わないでください. デザイントークンの中にない場合はデザイナと相談してください. */
export const core = {
  white: '#ffffff',
  black: '#000000',
  keycolor50: '#e2f1ff',
  keycolor100: '#c3dcee',
  keycolor200: '#a7c2d9',
  keycolor300: '#89a9c3',
  keycolor400: '#7295b2',
  keycolor500: '#5a82a2',
  keycolor600: '#4c7390',
  keycolor700: '#3d5f78',
  keycolor800: '#2f4c62',
  keycolor900: '#283C4B',
  gray50: '#f7f7f7',
  gray100: '#eeeeee',
  gray200: '#e3e3e3',
  gray300: '#d1d1d1',
  gray400: '#acacac',
  gray500: '#8b8b8b',
  gray600: '#646464',
  gray700: '#515151',
  gray800: '#333333',
  gray900: '#131313',
  grayalpha50: '#1313130a',
  grayalpha100: '#13131312',
  grayalpha200: '#1313131f',
  grayalpha300: '#13131333',
  grayalpha400: '#13131359',
  grayalpha500: '#1313137d',
  grayalpha600: '#131313a8',
  grayalpha700: '#131313bd',
  grayalpha800: '#131313de',
  grayalpha900: '#131313',
  bluegray50: '#fafbfc',
  bluegray100: '#f5f6f7',
  bluegray200: '#f0f1f2',
  bluegray300: '#e4e5e6',
  bluegray400: '#c2c3c4',
  bluegray500: '#a3a4a5',
  bluegray600: '#7a7b7c',
  bluegray700: '#666667',
  bluegray800: '#464748',
  bluegray900: '#252626',
  blue50: '#e3f2ff',
  blue100: '#bbdeff',
  blue200: '#90cbff',
  blue300: '#63b6ff',
  blue400: '#40a6ff',
  blue500: '#1f97ff',
  blue600: '#2188f0',
  blue700: '#1f76dd',
  blue800: '#1e65ca',
  blue900: '#1a46ab',
  lightblue50: '#e2f6ff',
  lightblue100: '#b5e8ff',
  lightblue200: '#84d9ff',
  lightblue300: '#52caff',
  lightblue400: '#2cbeff',
  lightblue500: '#0bb2ff',
  lightblue600: '#0aa3f0',
  lightblue700: '#0890db',
  lightblue800: '#087fc7',
  lightblue900: '#065ea5',
  indigo50: '#e8eaf6',
  indigo100: '#c5cae9',
  indigo200: '#9fa8da',
  indigo300: '#7986cb',
  indigo400: '#5c6bc0',
  indigo500: '#3f51b5',
  indigo600: '#3949ab',
  indigo700: '#303f9f',
  indigo800: '#283593',
  indigo900: '#1a237e',
  lime50: '#f7fce6',
  lime100: '#ecf7c0',
  lime200: '#dff396',
  lime300: '#d2ee69',
  lime400: '#c7e944',
  lime500: '#bee60e',
  lime600: '#b3d300',
  lime700: '#a3bc00',
  lime800: '#94a500',
  lime900: '#7c7e00',
  yellow50: '#FFF5D6',
  yellow100: '#FBE4A2',
  yellow200: '#F6D26B',
  yellow300: '#F1C32E',
  yellow400: '#EEB500',
  yellow500: '#EBA900',
  yellow600: '#EC9B00',
  yellow700: '#EC8800',
  yellow800: '#EC7700',
  yellow900: '#EC5400',
  orange50: '#fff3e1',
  orange100: '#ffe0b5',
  orange200: '#ffcc86',
  orange300: '#ffb757',
  orange400: '#ffa737',
  orange500: '#ff9923',
  orange600: '#fb8d21',
  orange700: '#f47e1f',
  orange800: '#ed6e1d',
  orange900: '#e3551a',
  red50: '#fbe8e7',
  red100: '#ffcabb',
  red200: '#ffa88f',
  red300: '#ff8563',
  red400: '#ff6941',
  red500: '#ff4d20',
  red600: '#f4471c',
  red700: '#e64017',
  red800: '#d83913',
  red900: '#bf2b08',
  pink50: '#FCEAF1',
  pink100: '#f7bbd2',
  pink200: '#f390b4',
  pink300: '#ef6396',
  pink400: '#eb417f',
  pink500: '#e91e68',
  pink600: '#d81c65',
  pink700: '#c21960',
  pink800: '#ac165b',
  pink900: '#871152',
  purple50: '#f4e5f6',
  purple100: '#e3bfe9',
  purple200: '#d195db',
  purple300: '#be6acd',
  purple400: '#af4ac1',
  purple500: '#a12ab6',
  purple600: '#9327b0',
  purple700: '#7f22a8',
  purple800: '#6e1ea0',
  purple900: '#4e1792',
  green50: '#ecf8e6',
  green100: '#d1edc1',
  green200: '#b2e198',
  green300: '#91d56d',
  green400: '#76cc4a',
  green500: '#5ac222',
  green600: '#4ab319',
  green700: '#319f0d',
  green800: '#0e8a00',
  green900: '#006900',
  cyan50: '#e0f7fa',
  cyan100: '#b2ebf2',
  cyan200: '#80deea',
  cyan300: '#4dd0e1',
  cyan400: '#26c6da',
  cyan500: '#00bcd4',
  cyan600: '#00acc1',
  cyan700: '#0097a7',
  cyan800: '#00838f',
  cyan900: '#006064',
  grayAlpha50: '#1313130a',
  grayAlpha100: '#13131312',
  grayAlpha200: '#1313131f',
  grayAlpha300: '#13131333',
  grayAlpha400: '#13131359',
  grayAlpha500: '#1313137d',
  grayAlpha600: '#131313a8',
  grayAlpha700: '#131313bd',
  grayAlpha800: '#131313de',
  grayAlpha900: '#131313f5',
  whiteAlpha50: '#ffffff0a',
  whiteAlpha100: '#ffffff12',
  whiteAlpha200: '#ffffff1f',
  whiteAlpha300: '#ffffff33',
  whiteAlpha400: '#ffffff59',
  whiteAlpha500: '#ffffff7d',
  whiteAlpha600: '#ffffffa8',
  whiteAlpha700: '#ffffffbd',
  whiteAlpha800: '#ffffffde',
  whiteAlpha900: '#fffffff5',
};

/** @deprecated CSS Variables を使ってください. */
export const WHColor = {
  background: {default: core.bluegray100, overlay: core.grayAlpha500, overlayDark: core.grayAlpha600},
  surface: {
    neutralLow: core.white,
    neutralMiddle: core.bluegray50,
    neutralHigh: core.bluegray200,
    neutralDark: core.grayAlpha800,
    brandPrimary: core.keycolor900,
    brandSecondary: core.keycolor600,
    brandAccent: core.lightblue400,
    accentDefault: core.blue600,
    accentLight: core.blue50,
    semanticError: core.pink600,
    semanticErrorLight: core.pink50,
    semanticWarning: core.yellow700,
    semanticWarningLight: core.yellow50,
    semanticSuccess: core.green600,
    semanticSuccessLight: core.green50,
    semanticInfo: core.blue600,
    semanticInfoLight: core.blue50,
    expressiveOrange: core.orange400,
    expressiveRed: core.red400,
    expressivePink: core.pink300,
    expressivePurple: core.purple400,
    expressiveIndigo: core.indigo600,
    expressiveLightblue: core.lightblue400,
    expressiveCyan: core.cyan500,
    expressiveLime: core.lime600,
    expressiveOrangeLight: core.orange50,
    expressiveRedLight: core.red50,
    expressivePinkLight: core.pink50,
    expressivePurpleLight: core.purple50,
    expressiveIndigoLight: core.indigo50,
    expressiveLightblueLight: core.lightblue50,
    expressiveCyanLight: core.cyan50,
    expressiveLimeLight: core.lime50,
  },
  state: {
    hoverDefault: core.grayAlpha100,
    hoverOnDeep: core.whiteAlpha300,
    disabled: core.bluegray300,
    focus: core.blue50,
    highlightSearch: core.green100,
    highlightError: core.pink50,
    activeDefault: core.grayAlpha50,
  },
  text: {
    neutralPrimary: core.grayAlpha800,
    neutralSecondary: core.grayAlpha600,
    neutralDisabled: core.grayAlpha400,
    neutralWhitePrimary: core.white,
    neutralWhiteSecondary: core.whiteAlpha600,
    linkPrimary: core.blue700,
    linkPrimaryHover: core.blue900,
    semanticError: core.pink600,
    semanticWarning: core.yellow900,
    semanticSuccess: core.green800,
    semanticInfo: core.blue700,
    brandPrimary: core.keycolor900,
    brandSecondary: core.keycolor600,
    brandAccent: core.lightblue400,
    expressiveOrange: core.orange900,
    expressiveRed: core.red900,
    expressivePink: core.pink400,
    expressivePurple: core.purple700,
    expressiveIndigo: core.indigo900,
    expressiveLightblue: core.lightblue900,
    expressiveCyan: core.cyan700,
    expressiveLime: core.lime900,
  },
  object: {
    neutralPrimary: core.grayAlpha800,
    neutralSecondary: core.grayAlpha600,
    neutralDisabled: core.grayAlpha400,
    neutralWhitePrimary: core.white,
    neutralWhiteSecondary: core.whiteAlpha600,
    brandPrimary: core.keycolor900,
    brandSecondary: core.keycolor600,
    brandAccent: core.lightblue400,
    semanticError: core.pink600,
    semanticWarning: core.yellow700,
    semanticSuccess: core.green600,
    semanticInfo: core.blue700,
    expressiveOrange: core.orange400,
    expressiveRed: core.red400,
    expressivePink: core.pink300,
    expressivePurple: core.purple400,
    expressiveIndigo: core.indigo600,
    expressiveLightblue: core.lightblue400,
    expressiveCyan: core.cyan500,
    expressiveLime: core.lime600,
    expressiveGray: core.gray400,
  },
  border: {
    onLightDefault: core.grayAlpha200,
    onLightStrong: core.grayAlpha300,
    onDeepDefault: core.whiteAlpha300,
    onDeepStrong: core.whiteAlpha400,
    semanticError: core.pink600,
    semanticWarning: core.yellow700,
    semanticSuccess: core.green600,
    semanticInfo: core.blue600,
    expressiveOrange: core.orange600,
    expressiveRed: core.red400,
    expressivePink: core.pink300,
    expressivePurple: core.purple400,
    expressiveIndigo: core.indigo600,
    expressiveLightblue: core.lightblue400,
    expressiveCyan: core.cyan500,
    expressiveLime: core.lime600,
    brandPrimary: core.keycolor900,
    brandSecondary: core.keycolor600,
    brandAccent: core.lightblue400,
  },
};
// TODO: shadowColorは現状、デザインチームにおける定義には存在せず、
// coreトークン化するかどうかも微妙なので、取り急ぎ分離してここに置く。
// 最新のデザインでは、基本shadowは使っていないが古いデザインなどには存在しているため、
// 定義自体はしておく。
export const shadowColor = {
  shadowAlpha012: '#0000001F',
  shadowAlpha014: '#00000024',
  shadowAlpha020: '#00000033',
};
