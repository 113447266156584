import {css} from '@emotion/react';
import ButtonBase from '@mui/material/ButtonBase';
import {forwardRef, type ForwardRefRenderFunction, type PropsWithChildren, type PointerEvent} from 'react';

import {WHColor} from '@/common/styles/whColor';
import {WHRadius} from '@/common/styles/whRadius';
import {WHSpacing} from '@/common/styles/whSpacing';

const styles = {
  root: css`
    position: relative;
    overflow: hidden;
    padding: ${WHSpacing.spacing8};
    border-radius: ${WHRadius.radiusM};

    &:focus {
      box-shadow: inset 0 0 0 2px ${WHColor.border.brandAccent};
    }

    &:hover::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${WHColor.state.hoverDefault};
    }

    &[data-background='true'] {
      background-color: ${WHColor.surface.neutralHigh};
    }

    &[data-disruptive='true'] {
      color: ${WHColor.surface.semanticError};
    }

    &[disabled] {
      color: ${WHColor.object.neutralDisabled};
    }

    > svg {
      width: 16px;
      height: 16px;
    }
  `,
} as const;

type Props = PropsWithChildren<{
  background?: boolean;
  disruptive?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: (e: PointerEvent<HTMLButtonElement>) => void;
}>;

const _IconButton: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  {background = false, disruptive = false, disabled, className, onClick, children},
  ref
) => {
  return (
    <ButtonBase
      ref={ref}
      css={styles.root}
      className={className}
      disabled={disabled}
      data-background={background}
      data-disruptive={disruptive}
      onClick={onClick}
    >
      {children}
    </ButtonBase>
  );
};

export const IconButton = forwardRef(_IconButton);
